import React, { Fragment, useContext, useState } from 'react';

import Header from '../../shared/components/header/Header';
import { AuthContext } from '../../shared/context/auth-context';
import ShowList from '../components/ShowList';
import SubMenu from '../../shared/components/nav/subMenu';
import AddShowForm from '../../shared/components/Forms/AddShowForm';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import Show from './Show';

const showMenu = [
  {
    id: 0,
    title: 'Shows'
  },
  {
    id: 1,
    title: 'add Show'
  }
]



const Dashboard = props => {

  const {userId} = useContext(AuthContext);
  const [showAddShow, setShowAddShow] = useState(false);
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(true);
  const [update, setUpdate] = useState(true);
  const [showId, setShowId] = useState()

  const subMenuStateHandler = (stateId) => {
    console.log(stateId);
    
    switch (stateId) {
      case 0:
        setShowList(true);
        setShow(false);
              
        break;
      case 1:
        setShowAddShow(true);
        setShowList(true);
        setShow(false);
        break;
      case 2:
        setShowAddShow(false);
        setShowList(false);
        setShow(true);
        break;
    
      default:
        break;
    }
  }

  const updateList = () => {
    setUpdate(()=> update ? false : true);
    setShowAddShow(false)
  }

  const selectedShowId = showId => {
    console.log(showId);
    setShowId(showId);
    subMenuStateHandler(2);
  }

  return (
    <Fragment>
    <div className="page__wrapper">
      <Header title={'Dashboard'} />
      <SubMenu  items={showMenu} click={subMenuStateHandler} />
      <div className="page-content">
       {showList && <ShowList userId={userId} update={update} selectedShow={selectedShowId}/> } 
       {show && <Show showId={showId} backToList={()=>subMenuStateHandler(0)}/>}
      </div>
    </div>
    <Modal show={showAddShow} hideButton={true} >
                  <h2>Add Show</h2>
                  <AddShowForm onCancel={() => updateList()}/>
              </Modal>
    </Fragment>
  );
};

export default Dashboard;

import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import Input from '../FormElements/Input';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';

import './AddShowForm.css';
import Checkbox from '../FormElements/Checkbox';

const EditShow = ({name, date, id, onCancel }) => {
  const { userId } = useContext(AuthContext);
  const { register, handleSubmit } = useForm();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const onSubmit = async data => {

    console.log(data);

    let showName;
    let showDate;

    if (data.name) {
        showName = data.name;
    } else {
        showName = name;
    }

    if (data.date) {
        showDate = data.date;
    } else {
        showDate = date;
    }


    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/shows/update/${id}`,
        'PATCH',
        JSON.stringify({
          name: showName,
          userId: userId,
          active: data.active,
          date: showDate,
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      console.log(response);

      onCancel();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="add-show_Wrapper">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form className="add-show-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            id="name"
            refs={register({ required: false })}
            name="name"
            type="text"
            placeholder="Show Name"
            className="no-group"
          />
          
          <Input
            id="date"
            refs={register({ required: false })}
            name="date"
            type="date"
            placeholder="Show Date"
            className="no-group"
          />
          <div className='show-form-checkbox-container'>
          <h3 className='show-form-checkbox-title'>Active: </h3>
          <Checkbox 
            id="active"
            name="active"
            refs={register()}
           />
          </div>

          <div className="add-show-model-actions">
            
            <Button
              className="add-show-submit button-secondary"
              title="cancel"
              type="text"
              onClick={() => {
                onCancel();
              }}
            />
            <Button className="add-show-submit" title="update" type="submit" />
          </div>
        </form>
      )}
    </div>
  );
};

export default EditShow;

import React from 'react';
import Card from '../../shared/components/UIElements/Card';

import './PodiumQuestionItem.css';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PodiumQuestionItem = ({showId, name, question, questionId, update, selectedAt, selected}) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    let answered = '';
    let hidden = '';

    if (selectedAt) {
        answered = 'podium-answered';
    }

    if (selectedAt && !selected) {
        hidden = 'hidden';
    } 

    const selectQuestion = async (qId) => {

        console.log(qId);

        try {
            const response = await sendRequest(
              `${process.env.REACT_APP_API_URL}/questions/update/${qId}`,
              'PATCH',
              JSON.stringify({
                question: question,
                name: name,
                showId: showId,
                selected: true,
                selectedAt: new Date()
              }),
              {
                'Content-Type': 'application/json',
              }
            );
    
            update();
            console.log(response);
      
          } catch (err) {
            console.log(err);
          }
    }

    return (
        <Card className={`podium-question-card__wrapper ${hidden}`}>
            <div className='podium-question-card__content' >
            <div className={`podium-question-card-details `}>
            {selectedAt && <div className='podium-question-answered-badge'><FontAwesomeIcon icon="check-circle" size="lg" /><h2> &nbsp;Answered</h2></div>}
            {selected && <div className='podium-question-selected-badge'><FontAwesomeIcon icon="check-circle" size="lg" /><h2> &nbsp;Selected</h2></div>}
                <h2 className={`podium-question-card-h2 ${answered}`}>{question}</h2>
                <p className={`podium-question-card-p ${answered}`}>{name}</p>
            </div>
            <div className='podium-question-card-actions'>
            {!selected && !isLoading && <Button title='Select' onClick={() => selectQuestion(questionId)} />}
            </div>
               
            </div>
        </Card>
    )


}

export default PodiumQuestionItem;
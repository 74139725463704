import React from 'react';
import Card from '../../shared/components/UIElements/Card';

import './ShowItem.css';

const ShowItem = ({showName, showDate, showId, select}) => {

    

    

    return (
        <Card className='show-card__wrapper' >
            <div className='show-card__content' onClick={() => select(showId)}>
                <h2>{showName}</h2>
                <p>{showDate}</p>
            </div>
        </Card>
    )


}

export default ShowItem;
import React, { Fragment, useState } from 'react';
import {useParams} from "react-router-dom";
import PodiumQuestionList from '../components/PodiumQuestionList';
import './Podium.css';

const Podium = props => {

  const {sId} = useParams();

  console.log(sId);



  return (
    <Fragment>
    <div id="podium_page">
      
    <h1>Q&A Questions</h1>
    <div className='podium-selected-question'>

    </div>
    <div className='podium-question-container'>
      <PodiumQuestionList showId={sId} />
    </div>
    </div>
   
    </Fragment>
  );
};

export default Podium;

import React, {useEffect, useState} from 'react';
import './AvatarIcon.css';


const AvatarIcon = props => {
   
    const [src, setSrc] = useState()

    useEffect(() => {
        setSrc(props.src);
    }, [ props.src]);

    return (
        <div className={`avatar-icon-wrapper ${props.className}`}>
           <img 
            src={!src ? "https://meerkat.software/hfm/img/profile/profile-placeholder.svg" : src} 
            alt={props.alt} 
            />
        </div>
    )
}

export default AvatarIcon;
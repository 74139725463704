import React, { Fragment, useEffect, useState } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import ShowItem from './ShowItem';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import AddShowForm from '../../shared/components/Forms/AddShowForm';


import './ShowList.css'
const ShowList = ({userId, update, selectedShow}) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    
    const [shows, setShows] = useState([]);
    const [showAddShow, setShowAddShow] = useState(false);

    const select = id => {
      selectedShow(id);
      
    }
  
    useEffect(() => {
      const fetchShows = async () => {
          
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/shows/user/${userId}`
          );

          if (responseData.shows) {
            setShows(responseData.shows);
          } 
          
        } catch (err) {
            console.log(err);
        }
      };
      if (userId) {
        fetchShows();
      }
      
    }, [sendRequest, userId, showAddShow, update]);
  
    let loadedShows;
    if (shows.length > 0) {
        loadedShows = shows.map(show => (
        <ShowItem
          key={show.id}
          showId={show.id}
          showName={show.name}
          showDate={show.date}
          select={select}
        />
      ));
    }

    const addShowForm = () => {
setShowAddShow(true);
    }
  
    return (
     <Fragment>
        <ErrorModal error={error} onClear={clearError} />
  
        <div className="show-list-wrapper">
          <div className="show-list-content">
            
              {!loadedShows ? (
                <div className='add-show'>
                    <h2 className="show-list-message">You don't have any shows yet!</h2>
                    <Button title='Add Show'  onClick={addShowForm}/>
                </div>
              ) : (
                loadedShows
              )}

              <Modal show={showAddShow} hideButton={true} >
                  <h2>Add Show</h2>
                  <AddShowForm onCancel={() => setShowAddShow(false)}/>
              </Modal>
  
           
                <div className="center">
                  {isLoading && (
                    <div className="center">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              
            
          </div>
        </div>
        </Fragment>
    );
  };
  
  export default ShowList;
  
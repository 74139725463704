import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './Auth.css';

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [requestSent, setRequestSent] = useState(false);

  const submitHandler = async data => {
  

    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_API_URL}/auth/request-token`,
        'POST',
        JSON.stringify({
          email: data.email,
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      console.log(res);
      if (res.tokenSuccess) {
        setRequestSent(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
      <div className='center'> </div>
     
      <div className="wrapper center col">
        <div className="auth-wrapper">
          {!requestSent ? (
            <Card className="login-card" style={{ width: '100%' }}>
              <h1>Forgot Password?</h1>
            
              <form onSubmit={handleSubmit(submitHandler)}>
                <label>
                  <span className="inputError">
                    {errors.email &&
                      errors.email.type === 'required' &&
                      'Email is required!'}
                  </span>
                </label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email"
                  className="no-group form-padding"
                  refs={register({ required: true })}
                />
 {isLoading ? <LoadingSpinner asOverlay />: 
                <Button
                  type="submit"
                  className="button-primary login-btn"
                  title="Reset Password"
                />
                }
              </form>

              <Link className="forgot-link" to="/auth">
                Back to login
              </Link>
            </Card>
          ) : (
            <Card className="forgot-card">
              <h1>Forgot Password?</h1>
              <p className="reset-sent-notice">
                If your email exsists you have been sent a password reset email.
                This link expires in 30 mins. Please check your email for
                further instructions.
              </p>
              <Link className="forgot-link" to="/auth">
                Back to login
              </Link>
            </Card>
          )}
          <Card className="dark-card " style={{ width: '100%' }}>
            <div className="enrol-cta-container">
              <p>
              <FontAwesomeIcon icon="exclamation-circle" /> &nbsp;If you don't receive an email right away check your junk folder
              </p>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;

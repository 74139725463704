import React, { Fragment, useContext } from 'react';

import Header from '../../shared/components/header/Header';
import { AuthContext } from '../../shared/context/auth-context';
import SubMenu from '../../shared/components/nav/subMenu';


const showMenu = [
  {
    id: 0,
    title: 'my account'
  },
  {
    id: 1,
    title: 'something'
  }
]



const Settings = props => {

  const {userId} = useContext(AuthContext);
 

  const subMenuStateHandler = (stateId) => {
    console.log(stateId);
    
    switch (stateId) {
      case 0:
      
              
        break;
      case 1:
       
        break;
    
    
      default:
        break;
    }
  }

 

  return (
    <Fragment>
    <div className="page__wrapper">
      <Header title={'Settings'} />
      <SubMenu  items={showMenu} click={subMenuStateHandler} />
      <div className="page-content">
      <h1>Settings page</h1>
      </div>
    </div>
   
    </Fragment>
  );
};

export default Settings;

import React, { Fragment, useState } from 'react';
import Card from '../../shared/components/UIElements/Card';
import Modal from '../../shared/components/UIElements/Modal/Modal';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './QuestionItem.css';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';

const QuestionItem = ({name, question, questionId, removed, selectedAt, selected}) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [showWarning, setShowWarning] = useState(false);
    let answered = '';

    if (selectedAt) {
        answered = 'answered';
    }

    const deleteQuestion = async () => {
        setShowWarning(false);
        try {
            await sendRequest(
              `${process.env.REACT_APP_API_URL}/questions/remove/${questionId}`,
              'DELETE',
              {
                'Content-Type': 'application/json'
              }
            );
            removed(questionId);
          } catch (err) {
              console.log(err);
          }
    }

    

    return (
        <Fragment>
        {isLoading && <Spinner />}
        <Modal show={showWarning} hideButton={true}>
        <div className='question-warning__wrapper'>
            <h1>Are you sure?</h1>
            <p>This will delete the question from the system. This cannot be undone.</p>
            <div className='question-warning__actions'>
                <Button className='button-secondary question-warning__button' title='CANCEL' onClick={() => setShowWarning(false)} />
                <Button className='button-danger question-warning__button' title='DELETE' onClick={() => deleteQuestion()} />
            </div>
            </div>
        </Modal>
        <Card className='question-card__wrapper' >
            <div className='question-card__content' >
            <div className={`question-card-details `}>
            {selectedAt && <div className='question-answered-badge'><FontAwesomeIcon icon="check-circle" size="lg" /><h1> &nbsp;Answered</h1></div>}
            {selected && <div className='question-selected-badge'><FontAwesomeIcon icon="check-circle" size="lg" /><h1> &nbsp;Selected</h1></div>}
                <h2 className={`question-card-h2 ${answered}`}>{question}</h2>
                <p className={`question-card-p ${answered}`}>{name}</p>
            </div>
            <div className='question-card-actions'>
            {!selected &&
                <Button className='button-danger' title='delete' onClick={() => setShowWarning(true)} />
            }
            </div>
               
            </div>
        </Card>
        </Fragment>
    )


}

export default QuestionItem;
import React, { Fragment, useEffect, useState } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import QuestionItem from './PodiumQuestionItem';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Modal from '../../shared/components/UIElements/Modal/Modal';


import './PodiumQuestionList.css'

const PodiumQuestionList = ({showId}) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    
    const [questions, setQuestions] = useState([]);
    const [update, setUpdate] = useState(false);

    const updateList = () => {
      if (update) {
        setUpdate(false);
      } else {
        setUpdate(true);
      }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const fetchShows = async () => {
          
                try {
                  const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/questions/${showId}`
                  );
        
                  if (responseData.questions) {
                    setQuestions(responseData.questions);
                    console.log(responseData.questions);
                  } 
                  
                } catch (err) {
                    console.log(err);
                }
              };
              if (showId) {
                fetchShows();
              }
        }, 10000);
        return () => clearInterval(interval);
      }, []);
  
    useEffect(() => {
      const fetchShows = async () => {
          
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/questions/${showId}`
          );

          if (responseData.questions) {
            setQuestions(responseData.questions);
            console.log(responseData.questions);

          } 
          
        } catch (err) {
            console.log(err);
        }
      };
      if (showId) {
        fetchShows();
      }
      
    }, [sendRequest, showId, update]);
  
    let loadedQuestions;
    if (questions.length > 0) {
        loadedQuestions = questions.map(q => (
        <QuestionItem
          key={q.id}
          showId={showId}
          questionId={q.id}
          question={q.question}
          name={q.name}
          selectedAt={q.selectedAt}
          selected={q.selected}
          update={updateList}
        />
      ));
    }

  
    return (
     <Fragment>
        <ErrorModal error={error} onClear={clearError} />
  
        <div className="podium-question-list-wrapper">
          <div className="podium-question-list-content">
            
              {!loadedQuestions ? (
                <div className='add-show'>
                    <h2 className="show-list-message">No Questions yet!</h2>
                </div>
              ) : (
                loadedQuestions
              )}
           {isLoading &&  <h2>checking...</h2>}

          </div>
        </div>
        </Fragment>
    );
  };
  
  export default PodiumQuestionList;
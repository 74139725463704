import React, {Suspense} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
  faHome,
  faCog
} from '@fortawesome/free-solid-svg-icons';

import SideBar from './shared/components/nav/sidebar';
import Dashboard from './dashboard/pages/dashboard';
import Auth from './auth/pages/Auth';
import Forgot from './auth/pages/ForgotPassword';
import SignUp from './auth/pages/SignUp';
import Reset from './auth/pages/ResetPassword';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';
import Settings from './settings/pages/Settings';
import Podium from './podium/pages/Podium';

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle,
  faHome,
  faCog
);

function App() {

  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  let routes;

  if (!token) {
    routes = (
      <React.Fragment>
          <Switch>
            <Route path="/auth" exact>
              <Auth />
            </Route>
            <Route path="/forgot" exact>
              <Forgot />
            </Route>
            <Route path="/enrol" exact>
            <SignUp />
          </Route>
            <Route path="/reset/:token">
            <Reset />
          </Route>
            <Redirect to="/auth" />
          </Switch>
      </React.Fragment>
    );
  } else {
    routes = (
      <React.Fragment>
        <SideBar />
          <Switch>
            <Route path="/settings" exact>
              <Settings />
            </Route>
            <Route path="/podium/:sId" exact>
              <Podium />
            </Route>
            <Route path="/" exact>
              <Dashboard />
            </Route>
            <Redirect to="/" />
          </Switch>
      </React.Fragment>
    );
  }




  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl : userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}
    >
      <Router>
       
        <main>
          <Suspense 
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;




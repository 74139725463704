import React from 'react';
import Button from '../Buttons/Button';

import Modal from './Modal';



const ErrorModal = props => {
  return (
    <Modal
      onCancel={props.onClear}
      show={!!props.error}
      footer={<Button onClick={props.onClear} title="Ok"/>}
    >
    <h1>Error!</h1>
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;

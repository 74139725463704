import React from 'react';

import './LoadingSpinner.css';

const Spinner = () => {

  return (
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <p id="lds-roller-text">Loading</p>
    </div>
  );
};

export default Spinner;

import React, { useContext }  from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Auth.css';



const Auth = props => {

  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const navEnrolHandler = event => {
    history.push("/enrol");
   }

  const submitHandler = async event => {
    event.preventDefault();

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/auth/`,
        'POST',
        JSON.stringify({
          email: event.target.email.value,
          password: event.target.password.value,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: auth.token
        }
        );
        auth.login(response.userId, response.email, response.profileImageUrl, response.firstName, response.lastName, response.token);
        history.push("/");
    }
    catch (err) {
      console.log(err);
    }
  }

    return (
        <React.Fragment>
            <ErrorModal
                error={error}
                className={'fm-modal-sm'}
                onClear={clearError}
            />
             {isLoading && <div className='spinner-center'><LoadingSpinner  /></div>}
            <div className="wrapper center col"> 
            <div className="auth-wrapper">
            {!isLoading && <Card className="login-card" style={{width:'100%'}}>
            <h1>Login</h1>
            <p>QAfeed for vMix</p>
            <form onSubmit={submitHandler}>
                <Input id="email" name="email" type="email" placeholder="email" className="no-group form-padding" />
                <Input id="password" name="password" type="password" placeholder="Password" className="no-group form-padding"/>
                <Button  type="submit" className="button-primary login-btn"  title="Login"/>
            </form>
            <Link className="forgot-link" to="/forgot">Forgot your password?</Link>
            </Card>}
            <Card className="primary-card " style={{width:'100%'}}>
                <div className="enrol-cta-container">
                    <p>Don’t have an account?</p>
                    <Button type="button" onClick={navEnrolHandler} className="button-secondary enrol-btn" title="Create" />
                </div>
            </Card>
            </div>
            </div>
            <div className='version-number'>QAfeed v0.1.0 Copyright 2021</div>
        </React.Fragment>
        );
};

export default Auth;
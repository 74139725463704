import React, { Fragment, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import './AddQuestion.css';

const AddQuestion = ({ showId, update}) => {
    const { userId } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submit = async data => {
    try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/questions/create-question`,
          'POST',
          JSON.stringify({
            question: data.question,
            name: data.name,
            showId: showId,
            userId: userId
          }),
          {
            'Content-Type': 'application/json',
          }
        );
  
        console.log(response);
        update();
  
      } catch (err) {
        console.log(err);
      }
  };

  return (
    <Fragment>
      <section id="add-question">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form className="add-question-form" onSubmit={handleSubmit(submit)}>
        <div className='add-question-input-outer_wrapper'>

        <label>
            <span className="add-question-inputError">
              {errors.name &&
                errors.name.type === 'required' &&
                'Question is required'}
              {errors.name &&
                errors.name.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>
          <Input
            id="question"
            refs={register({ required: true, minLength: 2 })}
            name="question"
            type="text"
            placeholder="Enter a question"
            className="no-group add-question-input"
            wrapperClassName="add-question-input-wrapper"
          />

        </div>
        <div className='add-question-input-outer_wrapper'>


          <label>
            <span className="add-question-inputError">
              {errors.name &&
                errors.name.type === 'required' &&
                'name is required'}
              {errors.name &&
                errors.name.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>
          <Input
            id="name"
            refs={register({ required: true, minLength: 2 })}
            name="name"
            type="text"
            placeholder="Asked by..."
            className="no-group add-question-input"
            wrapperClassName="add-question-input-wrapper"
          />
          </div>
          <Button type='submit' className='add-qustion-btn' title='add'/>
        </form>
      )};
      </section>
    </Fragment>
  );
};

export default AddQuestion;

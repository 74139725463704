import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { NavLink } from 'react-router-dom'; 



import './mainNav.css';

 const MainNav = () => {


    return (
        <div className="main-nav__wrapper">
            <ul className="nav-links">
                <li>
                    <NavLink to="/">
                    <FontAwesomeIcon icon={'home'} size={"2x"}/>
                    Dashboard</NavLink>
                </li>
               
                <li>
                    <NavLink to="/settings">
                    <FontAwesomeIcon icon={'cog'} size={"2x"}/>
                    Settings
                    </NavLink>
                </li>
            </ul>
 
        </div>
    )
}


export default MainNav;
import React, { useContext }  from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ImageUpload from '../../shared/components/FormElements/ImageUpload';
import './SignUp.css';



const SignUp = props => {

  const auth = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
 
  const navLoginHandler = event => {
    history.push("/auth");
  }

  const onSubmit = async data => {

    console.log(data.image[0]);

    try {
      const formData = new FormData();
      formData.append('email', data.email);
      formData.append('password', data.password );
      formData.append('firstName', data.firstName );
      formData.append('lastName', data.lastName );
      formData.append('image', data.image[0]);

      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/create-user`,
        'POST',
        formData
        );
        
        auth.login(response.userId, response.email, response.profileImageUrl, response.firstName, response.lastName, response.token);
        history.push("/");
    }
    catch (err) {
      console.log(err);
    }
  }

    return (
        <React.Fragment>
            <ErrorModal
                error={error}
                className={'fm-modal-sm'}
                onClear={clearError}
            />
             {isLoading && <LoadingSpinner asOverlay />}
            <div className="wrapper center col"> 
            {!isLoading && 
            <div className="auth-wrapper">
            <Card className="signup-card" style={{width:'100%'}}>
            <h1>Create an account</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="signup-form">
              
                <label>
                <span className="inputError">
                {errors.firstName && errors.firstName.type === 'required' && 'First name is required'}
                {errors.firstName && errors.firstName.type === 'minLength' && 'At least 2 characters are required!'}
                </span>
                </label> 
                <Input id="firstName" refs={register({ required: true, minLength: 2 })} name="firstName" type="firstName" placeholder="First Name" className="no-group form-padding" />
              
                <label>
                <span className="inputError">
                {errors.lastName && errors.lastName.type === 'required' && 'Your last name is required'}
                {errors.lastName && errors.lastName.type === 'minLength' && 'At least 2 characters are required!'}
                </span>
                </label> 
                <Input id="lastName" refs={register({ required: true, minLength: 2 })} name="lastName" type="lastName" placeholder="Last Name" className="no-group form-padding" />
                
                <label>
                <span className="inputError">
                {errors.image && errors.image.type === 'required' && 'You must provide a profile image'}
                </span>
                </label> 
                <ImageUpload id="image" refs={register({ required: true })}  />
                
                <label>
                <span className="inputError">
                {errors.email && errors.email.type === 'required' && 'Email is required'}
                </span>
                </label> 
                
                <Input id="email" refs={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                  },
                })} name="email" type="email" placeholder="email" className="no-group form-padding" />
                <label>
                <span className="inputError">
                {errors.password && errors.password.type === 'required' && 'Password is required'}
                {errors.password && errors.password.type === 'minLength' && 'At least 6 characters are required!'}
                </span>
                </label> 
                <Input id="password" refs={register({ required: true, minLength: 6 })} name="password" type="password" placeholder="Password" className="no-group form-padding"/>
                
                <Button  type="submit" className="button-primary signup-btn"  title="Create"/>
            </form>
            </Card>
            <Card className="primary-card " style={{width:'100%'}}>
                <div className="enrol-cta-container">
                    <p>Already have an account?</p>
                    <Button type="button" onClick={navLoginHandler} className="button-secondary enrol-btn" title="Login" />
                </div>
            </Card>
            </div>
            }
            </div>
        </React.Fragment>
        );
};

export default SignUp;
import React, { Fragment, useEffect, useState } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import QuestionItem from './QuestionItem';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Modal from '../../shared/components/UIElements/Modal/Modal';


import './QuestionList.css'
const QuestionList = ({showId, update}) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    
    const [questions, setQuestions] = useState([]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            const fetchShows = async () => {
          
                try {
                  const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/questions/${showId}`
                  );
        
                  if (responseData.questions) {
                    setQuestions(responseData.questions);
                    console.log(responseData.questions);
                  } 
                  
                } catch (err) {
                    console.log(err);
                }
              };
              if (showId) {
                fetchShows();
              }
        }, 10000);
        return () => clearInterval(interval);
      }, []);

    const remove = (id) => {
      setQuestions(questions.filter(function(q) { return q.id != id; }))
    }  
  
    useEffect(() => {
      const fetchShows = async () => {
          
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/questions/${showId}`
          );

          if (responseData.questions) {
            setQuestions(responseData.questions);
            console.log(responseData.questions);
          } 
          
        } catch (err) {
            console.log(err);
        }
      };
      if (showId) {
        fetchShows();
      }
      
    }, [sendRequest, showId, update]);
  
    let loadedQuestions;
    if (questions.length > 0) {
        loadedQuestions = questions.map(q => (
        <QuestionItem
          key={q.id}
          questionId={q.id}
          question={q.question}
          name={q.name}
          selectedAt={q.selectedAt}
          selected={q.selected}
          removed={remove}
        />
      ));
    }

  
    return (
     <Fragment>
        <ErrorModal error={error} onClear={clearError} />
  
        <div className="question-list-wrapper">
          <div className="question-list-content">
            
              {!loadedQuestions ? (
                <div className='add-show'>
                    <h2 className="show-list-message">You don't have any Questions yet!</h2>
                </div>
              ) : (
                loadedQuestions
              )}
           {isLoading &&  <h2>working...</h2>}

          </div>
        </div>
        </Fragment>
    );
  };
  
  export default QuestionList;
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { useHttpClient } from '../../shared/hooks/http-hook';

import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import EditShowForm from '../../shared/components/Forms/EditShowForm';


import './Show.css'
import AddQuestion from '../components/AddQuestion';
import QuestionList from '../components/QuestionList';
const Show = ({showId, backToList}) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [show, setShow] = useState();
    const [showEditShow, setShowEditShow] = useState(false);
    const [update, setUpdate] = useState(false);
    const history = useHistory();
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
      const fetchShow = async () => {

        console.log(showId);
          
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/shows/${showId}`
          );

          if (responseData.show) {
              console.log(responseData.show);
            setShow(responseData.show);
          } 
          
        } catch (err) {
            console.log(err);
        }
      };
      if (showId) {
        fetchShow();
      }
      
    }, [sendRequest, update, showId, showEditShow]);



    const editShowForm = () => {
        setShowEditShow(true);
    }

    const refreshList = () => {
        if (update) {
            setUpdate(false);
        } else {
            setUpdate(true);
        }
    }

    const deleteShow = async () => {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/shows/remove/${showId}`,
          'DELETE',
          {
            'Content-Type': 'application/json'
          }
        );
        backToList();
      } catch (err) {
          console.log(err);
      }
    }
  
    return (
     <Fragment>
        <ErrorModal error={error} onClear={clearError} />
        <Modal show={showWarning} hideButton={true}>
        <div className='question-warning__wrapper'>
            <h1>Are you sure?</h1>
            <p>You are about to delete the show from the system. This cannot be undone.</p>
            <div className='question-warning__actions'>
                <Button className='button-secondary question-warning__button' title='CANCEL' onClick={() => setShowWarning(false)} />
                <Button className='button-danger question-warning__button' title='DELETE' onClick={() => deleteShow()} />
            </div>
            </div>
        </Modal>
  
        <div className="show-wrapper">
          <div className="show-content">
            <h2 className='show-header-deatils'><span className='show-header-deatils-tag'>Show ID:</span> {showId}</h2>
            <h2 className='show-header-deatils'><span className='show-header-deatils-tag'>Show Name:</span>  {show && show.name}</h2>
            <h2 className='show-header-deatils'><span className='show-header-deatils-tag'>Show Date:</span>  {show && show.date}</h2>
            <h2 className='show-header-deatils'><span className='show-header-deatils-tag'>Status:</span>  {show && show.active ? 'ACTIVE' : 'INACTIVE'}</h2>
            <h2 className='show-header-deatils'><span className='show-header-deatils-tag'>vMix URL:</span>  {show && `https://qafeed.api.helogale.app/api/showfeed/${showId}`}</h2>
            <div className='show-actions'>
                <Button className='button-primary' onClick={editShowForm} title='Edit Show'/>
                <Button className='button-secondary' onClick={()=> history.push(`/podium/${showId}`)} title='Podium'/>
                <Button className='button-danger' onClick={()=> setShowWarning(true)} title='Delete Show'/>
            </div>
           
            <AddQuestion showId={showId} update={refreshList}/>
            <div className='question-list-continer'>
                <QuestionList showId={showId} update={update} />
            </div>




              <Modal show={showEditShow} hideButton={true} >
                  <h2>Edit Show</h2>
                  {show && <EditShowForm name={show.name} date={show.date} id={show.id} onCancel={() => setShowEditShow(false)}/>}
              </Modal>
  
           
                <div className="center">
                  {isLoading && (
                    <div className="center">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              
          </div>
        </div>
        </Fragment>
    );
  };
  
  export default Show;
  
import React from 'react'
import logo from '../../../logo.png';

import './logo.css'

 const Logo = () => {
    return (
        <div className="logo__wrapper">
             <img className="logo-img" src={logo} alt="logo" />
        </div>
    )
}


export default Logo;
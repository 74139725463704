import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import Input from '../FormElements/Input';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';

import './AddShowForm.css';

const AddShow = ({ onCancel }) => {
  const { userId } = useContext(AuthContext);
  const { register, handleSubmit, errors } = useForm();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const onSubmit = async data => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/shows/create-show`,
        'POST',
        JSON.stringify({
          name: data.name,
          userId: userId,
          date: data.date,
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      console.log(response);

      onCancel();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="add-show_Wrapper">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form className="add-show-form" onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span className="add-show-inputError">
              {errors.name &&
                errors.name.type === 'required' &&
                'Show name is required'}
              {errors.name &&
                errors.name.type === 'minLength' &&
                'At least 2 characters are required!'}
            </span>
          </label>
          <Input
            id="name"
            refs={register({ required: true, minLength: 2 })}
            name="name"
            type="text"
            placeholder="Show Name"
            className="no-group"
          />

          <label>
            <span className="add-show-inputError">
              {errors.date &&
                errors.date.type === 'required' &&
                'Show date is required'}
            </span>
          </label>
          <Input
            id="date"
            refs={register({ required: true })}
            name="date"
            type="date"
            placeholder="Show Date"
            className="no-group"
          />

          <div className="add-show-model-actions">
            
            <Button
              className="add-show-submit button-secondary"
              title="cancel"
              type='text'
              onClick={() => {
                onCancel();
              }}
            />
            <Button className="add-show-submit" title="add" type="submit" />
          </div>
        </form>
      )}
    </div>
  );
};

export default AddShow;
